/* src/pages/SignUpPage.css */
/* .signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .signup-form {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
  }
  
  .signup-form h2 {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    text-align: left;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .btn {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .btn:hover {
    background-color: #0056b3;
  }
   */

/* src/pages/SignUpPage.css */

.login-outer{
	padding: 0;
	background-image: url(../../assets/images/copilot-main-bg.jpg);
	background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .signup-form {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
  }
  
  .signup-form h2 {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    text-align: left;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .btn {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .btn:hover {
    background-color: #0056b3;
  }
  
  .signuptext {
    display: inline-block;
    position: relative;
    top: 7px;
    margin-left: 15px !important;
  }
  
  .poweredby {
    max-width: inherit !important;
    text-align: center;
    margin: 0 !important;
    position: relative;
    bottom: -20px;
  }
  
  .poweredby b {
    color: #1aa1db;
  }
  
  .login-button {
    color: #fff !important;
    text-transform: none !important;
  }
  
  .login-box {
    margin-top: 50% !important;
  }
  
  .newdesignlabel label,
  .newdesignlabel svg {
    color:#999;
  }

  .signupmain {
    max-width: 650px !important;
  }
  
  .signuptext button {
    position: relative;
    top: -2px;
  }