.close-button {
    position: absolute;
    right: 8px;
    top: 8px;
    color: black;
    transition: color 0.3s ease, background-color 0.3s ease; /* Smooth transition for color and background */
  }
  
  .close-button:hover {
    color: #1976d2; /* Replace with the desired hover color */
    background-color: rgba(0, 0, 0, 0.1); /* Optional background color on hover */
  }

