/* Sidebar.css */
.sidebar {
  width: 250px;
  height: 100%;
  background-color: #f0f0f0;
  padding: 20px;
  border-right: 1px solid #ccc;
  overflow-y: auto;
}

.sidebar h2 {
  margin-bottom: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  cursor: pointer;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.sidebar li:hover {
  background-color: #e0e0e0;
}

/* ChatPage.css */
.chat-container {
  display: flex;
  height: 100vh;
}

.chat-messages {
  flex: 1;
  padding: 20px;
}

.message-list {
  overflow-y: auto;
  height: calc(100vh - 80px); /* Adjust according to your layout */
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  max-width: 70%;
  word-wrap: break-word;
  background-color: #f0f0f0;
}

.chat-form {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.input-message {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.btn-send {
  margin-left: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.btn-send:hover {
  background-color: #0056b3;
}

/* Sidebar.css */

.sidebar-container {
  display: flex;
}

.sidebar-box {
  flex: 1;
  padding: 20px;
}

.sidebar-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.sidebar-logo {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.sidebar-title {
  margin-top: 6px;
}

.configurations-button {
  margin: 20px;
  background-color: #F8A900; /* Secondary color */
}

.conversation-list {
  flex: 1;
  overflow-y: auto;
}

.conversation-list-item {
  border-radius: 10px;
  box-shadow: none;
  margin: 8px 0;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.conversation-list-item:hover {
  background-color: #F8A900; /* Secondary color light */
  transform: scale(1.02);
}

.conversation-list-item:active {
  transform: scale(0.98);
}

.list-item-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: cursive;
  color: #333333; /* Dark text color */
}

.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px); /* Blurred background effect */
}

.modal-box {
  position: relative;
  max-width: 800px;
  background-color: #ffffff; /* Background color */
  box-shadow: 24px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #e0e0e0; /* Divider color */
}

.modal-close-button {
  position: absolute;
  right: 8px;
  top: 8px;
  color: #9e9e9e; /* Grey color */
}

.modal-close-button:hover {
  color: #00A0DC; /* Primary color */
}

.message-display {
  padding: 16px;
}

.message-item-question {
  background-color: #00A0DC; /* Primary color light */
  color: #ffffff; /* Text color */
  border-radius: 8px;
  padding: 8px;
  margin: 8px 0;
  align-self: flex-end;
  max-width: 80%;
  word-break: break-word;
}

.message-item-answer {
  background-color: #F8A900; /* Secondary color light */
  color: #ffffff; /* Text color */
  border-radius: 8px;
  padding: 8px;
  margin: 8px 0;
  align-self: flex-start;
  max-width: 80%;
  word-break: break-word;
}

