.chatContainer {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

@media (max-width: 768px) {
  .chatContainer {
    flex-direction: column;
  }
}

.sidebar {
  width: 240px;
  background-color: #f0f0f0;
  transition: width 0.3s ease;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    max-width: 100%;
  }
}

.chatArea {
  flex-grow: 1;
  background-color: #fff;
  transition: width 0.3s ease;
}

@media (max-width: 768px) {
  .chatArea {
    width: 100%;
    flex-grow: 1;
  }
}

.chatPaper {
  padding: 16px;
  margin: 20px auto;
  height: calc(100vh - 100px);
  overflow-y: auto;
}

@media (max-width: 768px) {
  .chatPaper {
    height: calc(100vh - 160px);
  }
}

.messageList {
  max-height: calc(100% - 64px);
  overflow-y: auto;
}

.inputArea {
  padding-top: 8px;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .inputArea {
    padding-top: 16px;
    flex-direction: column;
  }
}

.userMessage {
  text-align: right;
}

.botMessage {
  text-align: left;
}

.messageBubble {
  display: inline-block;
  padding: 8px;
  border-radius: 4px;
  margin: 8px 0;
}

.userBubble {
  background-color: #e0f7fa;
}

.botBubble {
  background-color: #f1f1f1;
}

.inputMessage {
  flex: 1;
}

.uploadButton {
  margin-left: 8px;
}

@media (max-width: 768px) {
  .uploadButton {
    margin-left: 0;
    margin-top: 8px;
  }
}

.btnSend {
  margin-left: 8px;
}


@media (max-width: 768px) {
  .btnSend {
    margin-left: 0;
    margin-top: 8px;
  }
}

.highlighted {
  background-color: #ffeb3b;
}


.css-vkruiu-MuiPaper-root {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  /* box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); */
  padding: 16px;
  margin: 20px auto;
  height: calc(100vh - 100px);
  overflow-y: auto;
  padding: 16px;
  margin: 20px auto;
  height: calc(100vh - 170px);
  overflow-y: auto;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  overflow-y: visible !important;
}

